import { Button, Container, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Background, ContentWrapper, Subtitle, Title } from './Banner.styles';

interface BannerProps {
  home?: boolean;
  image?: string;
  colour?: string;
  title: string;
  subtitle?: string;
  buttonText?: string;
  buttonLink?: string;
}

const Banner: FC<BannerProps> = ({
  home,
  image,
  colour,
  title,
  subtitle,
  buttonText,
  buttonLink,
}): ReactElement => {
  const theme = useTheme();

  return (
    <>
      <Container disableGutters maxWidth={false}>
        <Background
          home={home}
          image={image}
          colour={colour ?? theme.palette.primary.main}
        >
          <ContentWrapper>
            <Title variant="h1">{title}</Title>
            {subtitle && <Subtitle variant="h6">{subtitle}</Subtitle>}
            {buttonText && buttonLink && (
              <Button
                component={Link}
                to={buttonLink}
                variant="contained"
                sx={{ boxShadow: '#000000 0px 0 5px' }}
              >
                {buttonText}
              </Button>
            )}
          </ContentWrapper>
        </Background>
      </Container>
    </>
  );
};

export default Banner;
