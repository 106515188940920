import { FC } from 'react';
import Banner from '../_Banner/Banner';
import BannerImage from '../../../assets/images/banners/home_banner.png';

const HomeBanner: FC = () => {
  return (
    <>
      <Banner
        home
        image={BannerImage}
        title="Welcome to Ansar Jiu-Jitsu"
        buttonText="Timetable"
        buttonLink="/timetable"
      />
    </>
  );
};

export default HomeBanner;
