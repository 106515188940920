import { FC, ReactElement } from 'react';
import Banner from '../../components/Banners/_Banner/Banner';
import { Typography } from '@mui/material';

const Register: FC = (): ReactElement => {
  return (
    <>
      <Banner title="REGISTER" />
      <div style={{ textAlign: 'center' }}>
        <Typography>Nothing to see here yet...</Typography>
      </div>
    </>
  );
};

export default Register;
