import { Route, Routes } from 'react-router-dom';
import Coaches from './pages/Coaches/Coaches';
import Home from './pages/Home/Home';
import NotFound from './pages/NotFound/NotFound';
import Register from './pages/Register/Register';
import Timetable from './pages/Timetable/Timetable';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/coaches" element={<Coaches />} />
      <Route path="/timetable" element={<Timetable />} />
      <Route path="/register" element={<Register />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
